import React from "react"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import FormRefund from "./FormRefund"

function App() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={FormRefund} />
              <PrivateRoute path="/enviar-devolucoes" component={FormRefund} />
              <Route path="/login" component={Login} />
            </Switch>
          </AuthProvider>
        </Router>
    </Container>
  )
}

export default App
