import { Formik, FieldArray } from "formik";
import * as yup from "yup";
import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Row, Card, Alert } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"
import FormTextField from "./Form-Field";

const schema = yup.object({
  devolucoes: yup.array().of(
      yup.object().shape({
        orderId: yup.string().required('OrderId é obrigatório'),
        name: yup.string().required('Nome é obrigatório'),
        cpf: yup.string().required('CPF é obrigatório'),
        value: yup.number().required('Valor é obrigatório')
      })
  )
});

function FormExample() {
    const [error, setError] = useState([])
    const [message, setMessage] = useState([])
    const { currentUser, logout } = useAuth()
    const history = useHistory()

    async function handleLogout() {
        setError([])
        setMessage([])
    
        try {
          await logout()
          history.push("/login")
        } catch {
          setError("Failed to log out")
        }
      }

  return (
    <div className="w-100" style={{ maxWidth: "800px" }}>
    <Row>
      <Col>
        <Formik
          validationSchema={schema}
          onSubmit={async values => {
            setError([])
            setMessage([])

            values.devolucoes.forEach((devolucao) => {

                axios.post(`${process.env.REACT_APP_GATEWAY_URL}/v2/refund`, devolucao, {
                  headers: {
                      Authorization: `Api-Key ${process.env.REACT_APP_GRINGO_DESK_API_KEY}`,
                    }
                })
                .then((respose) => {
                  const successMsg = {
                    ...respose.data
                  }
                  setMessage(arr => [...arr, successMsg])
                }).catch((err) => {
                  const errorObj = {
                    msg: err.response.data.errorMessage,
                    ...devolucao
                  }
                  setError(arr => [...arr, errorObj])
                })
            })

          }
        }
          initialValues={{
            devolucoes: [{ orderId: "123456789", name: 'Amigo Gringo', cpf: "38937635609", value: 1.99 }],
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            isValid,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Col></Col>
              <FieldArray
                name="devolucoes"
                render={(arrayHelpers) => (
                  <>
                    {values.devolucoes && values.devolucoes.length > 0 ? (
                      values.devolucoes.map((devolucao, index) => (
                        <Card key={index}>
                          <Card.Header>
                            <Card.Title>Devolução {index + 1}</Card.Title>
                          </Card.Header>
                          <Card.Body>
                            <Form.Row>
                              <FormTextField
                                as={Col}
                                sm="4"
                                controlId={`devolucoes.${index}.orderId`}
                                label="Id Order"
                                type="text"
                                name={`devolucoes.${index}.orderId`}
                              />
                              <FormTextField
                                as={Col}
                                sm="4"
                                controlId={`devolucoes.${index}.name`}
                                label="Nome"
                                type="text"
                                name={`devolucoes.${index}.name`}
                              />
                              <FormTextField
                                as={Col}
                                sm="4"
                                controlId={`devolucoes.${index}.cpf`}
                                label="CPF"
                                type="text"
                                name={`devolucoes.${index}.cpf`}
                              />
                              <FormTextField
                                as={Col}
                                sm="4"
                                controlId={`devolucoes.${index}.value`}
                                label="Valor"
                                type="number"
                                name={`devolucoes.${index}.value`}
                              />
                            </Form.Row>
                            {message.length > 0 && message.map((success) => {
                              console.log(success)
                              if(''+success.orderid === devolucao.orderId){
                                return <Alert variant="success"><p>{`Sucesso ao enviar devolução para o pedido: ${success.orderid}`}</p> <p>{`chave de autenticação: ${success.rtrid}`}</p></Alert>
                              }
                            })}
                            {error.length > 0 && error.map((err) => {
                              if(err.orderId === devolucao.orderId){
                                return <Alert key={err.orderId} variant="danger">{err.msg}</Alert>
                              }
                            })}
                          </Card.Body>
                          <Card.Footer>
                            <Button
                              type="button"
                              variant="outline-danger"
                              size="lg"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              type="button"
                              variant="outline-success"
                              size="lg"
                              onClick={() =>
                                arrayHelpers.push({
                                  orderId: "",
                                  name: "",
                                  value: 0,
                                  cpf: ""
                                })
                              }
                            >
                              +
                            </Button>
                          </Card.Footer>
                        </Card>
                      ))
                    ) : (
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        Adicionar devolução
                      </Button>
                    )}
                  </>
                )}
              />
              <Col>
                <Button
                  disabled={!isValid}
                  variant="success"
                  as="input"
                  size="lg"
                  type="submit"
                  value="Submit"
                />
              </Col>
            </Form>
          )}
        </Formik>
      </Col>
    </Row>
    <div className="w-100 text-center mt-2">
        <Button variant="link" onClick={handleLogout}>
          Log Out
        </Button>
      </div>
    </div>
  );
}

export default FormExample;
