import firebase from "firebase/app"
import "firebase/auth"

const app = firebase.initializeApp({
  apiKey: "AIzaSyCVXPMwhhIQK9ckMyrOFSvgevoyYSmiG9s",
  authDomain: "gringo-staging.firebaseapp.com",
  databaseURL: "https://gringo-staging.firebaseio.com",
  projectId: "gringo-staging",
  storageBucket: "gringo-staging.appspot.com",
  messagingSenderId: "827392460223",
  appId: "1:827392460223:web:b5967bee5c1841ecbc8d0a"
})

export const auth = app.auth()
export default app
